import { createContext, ReactNode, useState, Suspense, useContext, useCallback, useMemo, useEffect, lazy } from 'react';

import { Brands, parseBrand } from '../Brand';
import { useFavicon } from '../shared/useFavicon';
import { TestHarness } from '../shared/TestHarness';
import { Boomerang } from './Boomerang';
import { Iovation } from './Iovation';
import { DEFAULT_LAYOUT_VIEW_MODEL } from './DefaultLayoutViewModel';
import { DialogContainer } from '../shared/Dialog';
import { WebAnalytics } from './WebAnalytics';
import { useGraphQL } from '../shared/useGraphQL';
import { GetLayoutViewModelQuery, MessageType } from '../GraphQL.Default';
import { useEffectAsync } from '../shared/useEffectAsync';
import { ContentSquare } from './ContentSquare';
import { HostValues } from './HostValues';
import { Spinner } from '../shared/Spinner';
import { GoogleTagManagerDataLayer } from './GoogleTagManagerDataLayer';
import { extractSessionData } from './extractSessionData';
import { useLocation } from 'react-router';

interface LayoutProps {
    children?: ReactNode;
    brand?: Brands;
}

const LazyLayoutSpeedyCash = lazy(() => import('./speedy-cash/Layout'));
const LazyLayoutCashMoney = lazy(() => import('./cash-money/Layout'));
const LazyLayoutLendDirect = lazy(() => import('./lend-direct/Layout'));
const LazyLayoutTitleMax = lazy(() => import('./title-max/Layout'));

const DEFAULT_LAYOUT_CONTEXT = {
    brand: parseBrand(process.env.REACT_APP_BRAND) ?? Brands.None,
    clientApplicationId: new URLSearchParams(window.location.search).get('client_application_id') || undefined,
    model: DEFAULT_LAYOUT_VIEW_MODEL,
    update: () => Promise.resolve<GetLayoutViewModelQuery['getLayoutViewModel']>(undefined),
};
const LayoutContext = createContext(DEFAULT_LAYOUT_CONTEXT);
export const LayoutContextProvider = LayoutContext.Provider;

export function useLayoutContext() {
    return useContext(LayoutContext);
}

function brandLayout(brand: Brands, props: LayoutProps) {
    switch (brand) {
        case Brands.SpeedyCash:
            return <LazyLayoutSpeedyCash {...props} />;
        case Brands.CashMoney:
            return <LazyLayoutCashMoney {...props} />;
        case Brands.LendDirect:
            return <LazyLayoutLendDirect {...props} />;
        case Brands.TitleMax:
            return <LazyLayoutTitleMax {...props} />;
        default:
            return <></>;
    }
}

export function Layout(props: LayoutProps) {
    const graphQLClient = useGraphQL();
    const { pathname } = useLocation();

    const [layoutViewModel, setLayoutViewModel] = useState(DEFAULT_LAYOUT_VIEW_MODEL);

    const update = useCallback(async () => {
        setLayoutViewModel((prev) => ({ ...prev, persistedMessage: undefined }));
        const result = (await graphQLClient.getLayoutViewModel())?.data?.getLayoutViewModel;
        if (result) {
            document.body.classList.toggle('is-internal', result.isInternal ?? false);
            setLayoutViewModel((prev) => ({ ...prev, ...result }));
        }
        return result;
    }, [graphQLClient]);

    useEffectAsync(() => update(), [update]);

    useEffect(() => {
        let tmr = 0;
        if (layoutViewModel.persistedMessage?.text && layoutViewModel.persistedMessage.type !== MessageType.Modal) {
            tmr = window.setTimeout(() => update(), 30e3);
        }
        return () => clearTimeout(tmr);
    }, [layoutViewModel.persistedMessage?.text, layoutViewModel.persistedMessage?.type, update]);

    const { brand: layoutBrand } = props;
    const { brand: defaultBrand } = DEFAULT_LAYOUT_CONTEXT;

    const brand = useMemo(
        () => (/adastra/i.test(pathname) ? Brands.AdAstra : layoutBrand ?? defaultBrand),
        [defaultBrand, pathname, layoutBrand]
    );

    const { clientApplicationId } = DEFAULT_LAYOUT_CONTEXT;
    const contextValue = useMemo(
        () => ({ brand, model: layoutViewModel, update, clientApplicationId }),
        [brand, layoutViewModel, update, clientApplicationId]
    );
    useFavicon(layoutViewModel.siteAddress + '/favicon.ico');

    const { sessionInfo } = layoutViewModel;
    const sessionData = useMemo(() => extractSessionData(sessionInfo), [sessionInfo]);

    return (
        <LayoutContextProvider value={contextValue}>
            <ContentSquare sessionInfo={sessionData} />
            <HostValues sessionInfo={sessionData} />
            <GoogleTagManagerDataLayer sessionInfo={sessionData} />
            <DialogContainer />
            <Suspense fallback={<Spinner />}>{brandLayout(brand, props)}</Suspense>
            <WebAnalytics />
            <TestHarness sessionInfo={sessionData} />
            <Iovation />
            <Boomerang />
        </LayoutContextProvider>
    );
}
