import { Brands } from '../Brand';
import { useLayoutContext } from '../layout/Layout';
import { KeyValuePair } from './KeyValuePair';
import { PROVINCE_LIST_ITEMS, STATE_LIST_ITEMS } from './ListItemHelper';
import { useGraphQL } from './useGraphQL';
import { MaskType } from './Textbox';

interface ILocalization {
    checking: string;
    company: string;
    customerRelationsFax: string;
    customerRelationsPhone: string;
    customerServiceEmail: string;
    customerServiceFax: string;
    customerServicePhone: string;
    consumerPrivacyEmail: string;
    consumerPrivacyFax: string;
    consumerPrivacyPhone: string;
    incomeVerificationEmail: string;
    isCashMoney: boolean;
    isLendDirect: boolean;
    isSpeedyCash: boolean;
    isTitleMax: boolean;
    privacyPolicyUrl: string;
    shortDateFormat: string;
    signIn: string;
    signOut: string;
    socialSecurityNumber: string;
    state: string;
    stateListItems: Readonly<KeyValuePair<string, string>[]>;
    nonRestrictedStateListItems: Readonly<KeyValuePair<string, string>[]>;
    zip: string;
    zipMask: MaskType;
}

const USA: ILocalization = {
    checking: 'Checking',
    company: '',
    customerRelationsFax: '',
    customerRelationsPhone: '',
    customerServiceEmail: '',
    customerServiceFax: '',
    customerServicePhone: '',
    consumerPrivacyEmail: '',
    consumerPrivacyFax: '',
    consumerPrivacyPhone: '',
    incomeVerificationEmail: '',
    isCashMoney: false,
    isLendDirect: false,
    isSpeedyCash: false,
    isTitleMax: false,
    privacyPolicyUrl: '/about-us/privacy-policy/',
    shortDateFormat: 'M/d/yyyy',
    signIn: 'Sign in',
    signOut: 'Sign out',
    socialSecurityNumber: 'Social Security Number',
    state: 'State',
    stateListItems: STATE_LIST_ITEMS,
    nonRestrictedStateListItems: [],
    zip: 'Zip',
    zipMask: 'zip-code',
};

const CM_CANADA: ILocalization = {
    checking: 'Chequing',
    company: 'Cash Money',
    customerRelationsFax: '1-416-213-1139',
    customerRelationsPhone: '1-877-857-7805',
    customerServiceEmail: 'customerservice@cashmoney.ca',
    customerServiceFax: '1-855-493-8101',
    customerServicePhone: '1-877-526-6639',
    consumerPrivacyEmail: 'privacy@cashmoney.ca',
    consumerPrivacyFax: '1-877-293-0311',
    consumerPrivacyPhone: '1-416-213-1113',
    incomeVerificationEmail: 'Verify@SpeedyCash.com',
    isCashMoney: true,
    isLendDirect: false,
    isSpeedyCash: false,
    isTitleMax: false,
    privacyPolicyUrl: '/about-us/privacy-policy/',
    shortDateFormat: 'dd/MM/yyyy',
    signIn: 'Log in',
    signOut: 'Log Out',
    socialSecurityNumber: 'Social Insurance Number',
    state: 'Province',
    stateListItems: PROVINCE_LIST_ITEMS,
    nonRestrictedStateListItems: [],
    zip: 'Postal code',
    zipMask: 'canada-postal-code',
};

const LD_CANADA: ILocalization = {
    checking: 'Chequing',
    company: 'Lend Direct',
    customerRelationsFax: '1-416-213-1139',
    customerRelationsPhone: '1-877-857-7805',
    customerServiceEmail: 'customerservice@lenddirect.ca',
    customerServiceFax: '1-855-775-8225',
    customerServicePhone: '1-855-630-5363',
    consumerPrivacyEmail: 'privacy@cashmoney.ca',
    consumerPrivacyFax: '1-877-293-0311',
    consumerPrivacyPhone: '1-416-213-1113',
    incomeVerificationEmail: 'Verify@SpeedyCash.com',
    isCashMoney: false,
    isLendDirect: true,
    isSpeedyCash: false,
    isTitleMax: false,
    privacyPolicyUrl: '/about-us/privacy-policy/',
    shortDateFormat: 'dd/MM/yyyy',
    signIn: 'Log in',
    signOut: 'Log Out',
    socialSecurityNumber: 'Social Insurance Number',
    state: 'Province',
    stateListItems: PROVINCE_LIST_ITEMS,
    nonRestrictedStateListItems: [],
    zip: 'Postal code',
    zipMask: 'canada-postal-code',
};

export function useLocalization() {
    const { model: layoutViewModel, brand } = useLayoutContext();
    const client = useGraphQL();

    const isAdAstra = brand === Brands.AdAstra;
    if (isAdAstra) {
        USA.customerServicePhone = '1-866-398-2089';
        USA.customerServiceFax = '1-316-771-8880';
        USA.customerServiceEmail = 'Support@AdAstraRecoveryServicesInc.com';
    }

    let currentBrand = USA;
    let nonRestrictedStates = STATE_LIST_ITEMS;
    const cachedStates = sessionStorage.getItem("nonRestrictedStates");

    switch (brand) {
        case Brands.CashMoney:
            currentBrand = CM_CANADA;
            break;
        case Brands.AdAstra:
        case Brands.SpeedyCash:
            if (cachedStates) {
                nonRestrictedStates = JSON.parse(cachedStates);
            } else {
                client.getNonRestrictedStates().then((response) => {
                    nonRestrictedStates = response?.data?.getNonRestrictedStates ?? STATE_LIST_ITEMS;
                    sessionStorage.setItem('nonRestrictedStates', JSON.stringify(nonRestrictedStates));
                });
            }

            USA.isSpeedyCash = true;
            USA.company = layoutViewModel?.isRapidCash ? 'Rapid Cash' : 'Speedy Cash';
            USA.customerRelationsFax = '1-316-771-8801';
            USA.customerRelationsPhone = '1-800-856-2911';
            USA.customerServiceEmail = 'customerservice@speedycash.com';
            USA.customerServiceFax = '1-888-333-0568';
            USA.customerServicePhone = '1-888-333-1360';
            USA.consumerPrivacyEmail = 'privacy@speedycash.com';
            USA.incomeVerificationEmail = 'Verify@SpeedyCash.com';
            USA.privacyPolicyUrl = '/about-us/privacy-policy/';
            USA.nonRestrictedStateListItems = nonRestrictedStates;
            currentBrand = USA;
            break;
        case Brands.LendDirect:
            currentBrand = LD_CANADA;
            break;
        case Brands.TitleMax:
            if (cachedStates) {
                nonRestrictedStates = JSON.parse(cachedStates);
            } else {
                client.getNonRestrictedStates().then((response) => {
                    nonRestrictedStates = response?.data?.getNonRestrictedStates ?? STATE_LIST_ITEMS;
                    sessionStorage.setItem('nonRestrictedStates', JSON.stringify(nonRestrictedStates));
                });
            }

            USA.isTitleMax = true;
            USA.company = 'TitleMax';
            USA.customerRelationsFax = '1-316-771-8801';
            USA.customerRelationsPhone = '1-888-869-4522';
            USA.customerServiceEmail = 'CustomerService@alerts.titlemax.com';
            USA.customerServiceFax = '1-888-333-0568';
            USA.customerServicePhone = '1-888-333-1360';
            USA.consumerPrivacyEmail = 'privacy@alerts.titlemax.com';
            USA.incomeVerificationEmail = 'Verify@alerts.titlemax.com';
            USA.privacyPolicyUrl = 'https://www.tmxdisclosures.com/titlemax/privacy-policy';
            USA.nonRestrictedStateListItems = nonRestrictedStates;
            currentBrand = USA;
            break;
        default:
            break;
    }

    return currentBrand;
}
